import React from 'react';
import { Link, router } from '@inertiajs/react';
import { useTranslation } from 'react-i18next';
import GenericLayout from '@/components/layout/GenericLayout';
import AuthBackground from '@/components/layout/AuthBackground';
import { getuserApiServiceInstance } from "@/lib/api/UserApiService";
import useIsMobile from '@/lib/hooks/useIsMobile';
import { useZodState } from "@/lib/hooks/useZodState";
import { z } from "zod";
import { Button } from '@/components/elements/UIElements/Button';
import { Input } from "@/components/input/Input";
import useUiState from "@/lib/hooks/useUiState";
import { Spinner } from "@/icons/loader/Spinner";

const LoginForm = () => {
  const { t } = useTranslation(['translation', 'messages']);
  const { isMobile } = useIsMobile();
  const { uiState, setUiState } = useUiState({
    loading: false,
  });

  // Define schema for login form validation
  const { validate, inputs, error, setZodSchema, setValues } = useZodState({
    initialValues: {
      email: "",
      password: "",
    },
    schema: z.object({
      email: z.string().min(1),
      password: z.string().min(1),
    }),
  });

  // Handle form submission
  const handleLogin = async (e) => {
    if (e) {
      e.preventDefault();
    }
    if (uiState.loading) return;

    if (validate()) {
      setUiState({ loading: true });

      try {
        const userApiService = getuserApiServiceInstance();
        const response = await userApiService.login({ email: inputs.email, password: inputs.password });
        router.visit(response.redirectUrl);
      } catch (error) {
        setUiState({
          loading: false,
          error: true,
          errorMsg: error?.message ?? t("An error occurred"),
        });
      }
    } else {
      setUiState({ loading: false });
    }
  };

  return (
    <AuthBackground
      mobileSubmitButton={
        <></>
      }
      modalContent={
        <div className="w-full flex flex-col justify-between items-end gap-[32px]">
          <span className="w-full text-2xl lg:text-[30px] font-semibold pb-5 lg:pb-0 tracking-[-0.6px]">
            {t("Login Form Title", { ns: "translation" })}
          </span>

          {uiState.error && uiState.errorMsg.length > 0 && (
            <div className="w-full unmask-me text-[14px] mb-[-16px] px-[12px] py-[8px] bg-destructive text-primaryforeground rounded-[12px]">
              {uiState.errorMsg}
            </div>
          )}

          <form onSubmit={handleLogin} className="flex flex-col gap-[32px] w-full">
            <div className="flex flex-col gap-[6px] text-sm">
              <label className="font-medium">{t("Email")}</label>
              <Input
                name="email"
                value={inputs.email}
                onChange={(value) => setValues({ email: value })}
                className={`w-full rounded-xl border ${error.email ? 'border-red-500' : 'border-border'}`}
                errorBorder={error.email}
              />
            </div>

            <div className="flex flex-col gap-[6px] text-sm">
              <label className="font-medium">{t("Password")}</label>
              <Input
                type="password"
                value={inputs.password}
                onChange={(value) => setValues({ password: value })}
                className={`rounded-xl border ${error.password ? 'border-red-500' : 'border-border'}`}
                errorBorder={error.password}
              />
            </div>


            {!isMobile
              ?
              (
                <>
                  {uiState.loading ? (
                    <Button value={<Spinner />} size="smallLong" />
                  ) : (
                    <Button value={t("Login")} size="smallLong" />
                  )}
                </>
              )
              :
              <>
                {uiState.loading ? (
                  <Button value={<Spinner />} size="smallLong" className="w-full" />
                ) : (
                  <Button onClick={handleLogin} value={t("Login")} size="smallLong" className="w-full" />
                )}
              </>
            }
            <Link
              href={route('reset-password')}
              className="w-fit mt-[6px] lg:pt-0 underline text-sm text-foreground rounded-md focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t("Have you forgot password")}
            </Link>
            { isMobile && <div className='text-xs' dangerouslySetInnerHTML={{ __html: t("LoginInfoMessage", { ns: "translation" }) }} />}

          </form>
        </div>
      }
    />
  );
};

LoginForm.layout = (page) => <GenericLayout children={page} />;

export default LoginForm;
